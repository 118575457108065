<template>
  <div v-if="!collapsed" class="w-full">
    <div class="w-full rounded-2xl">
      <Disclosure v-slot="{ open }">
        <DisclosureButton
          :class="[
            'flex w-full testing items-center gap-3 cursor-pointer mb-1 hover:bg-neutral-100 fill text-xsm py-2 relative overflow-hidden text-typography-disabled px-4',
            { 'pointer-events-none': route },
          ]"
        >
          <component :is="!open && !route ? Icons.LineFolder : Icons.LineFolderOpen" class="h-5 w-5 fill-fosho" />
          <span>{{ label }}</span>
          <component
            :is="!open && !route ? Icons.LineAngleRight : Icons.LineAngleUp"
            class="h-5 w-5 fill-fosho -ml-1"
          />
        </DisclosureButton>
        <DisclosurePanel class="pt-1 text-sm text-gray-500 ml-2" :static="route">
          <div v-for="option in items" :key="option.label" class="ml-2">
            <NavItem :to="(option as Navigatable).to">
              <component :is="(option as Navigatable).icon" class="h-5 w-5" />
              <span v-if="!collapsed">{{ (option as Navigatable).label }}</span>
            </NavItem>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import * as Icons from '@pulse/components/icons';

type Navigatable = {
  icon: unknown;
  label: string;
  to: string;
};

const props = defineProps({
  collapsed: {
    required: true,
    type: Boolean,
  },
  icon: {
    default: 'Icons.LineFolder',
    required: false,
    type: String,
  },
  items: {
    required: true,
    type: Array,
  },
  label: {
    required: true,
    type: String,
  },
});

const route = useRoute().fullPath.includes(props.label.toLowerCase());
</script>
